export default function IconTextWithGradient() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        background: "-webkit-linear-gradient(left, #52C41A, #F6FFED)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      }}
    >
      <img
        src="/images/sparkle_icon.png"
        alt="Scapia Logo"
        style={{ height: "10px", marginRight: "4px" }}
      />

      <span className="body02">Zero joining fees | Zero annual fees</span>
    </div>
  );
}
