"use client";
import { useState } from "react";
import ScapiaTextInput from "./ScapiaTextInput";
import ScapiaCheckBox from "./ScapiaCheckBox";
import ScapiaButton from "./ScapiaButton";
import { sendOTP } from "../network/loginService";
import IconTextWithGradient from "./IconTextWithGradient";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import PhoneAadharAlertBottomSheet from "./phoneAadharAlertBottomsheet";

import {
  firstNameValidator,
  indianMobileNumberValidator,
  lastNameValidator,
} from "./textValidators";

function LoginComponent({ onOtpRequestGenerated }) {
  const [firstNameFilled, setFirstNameFilled] = useState({});
  const [lastNameFilled, setLastNameFilled] = useState({});
  const [phoneNumberFilled, setPhoneNumberFilled] = useState({});
  const [whatsappCheckBoxTicked, setWhatsappCheckBoxTicked] = useState(true);
  const [isButtonSubmissionLoading, setIsButtonSubmissionLoading] =
    useState(false);
  const [fullNameLengthError, setFullNameLengthError] = useState(null);

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  function checkFieldsAndValidate() {
    var firstNameValidationError = firstNameValidator(firstNameFilled.value);
    var lastNameValidationError = lastNameValidator(lastNameFilled.value);
    var phoneNumberValidationError = indianMobileNumberValidator(
      phoneNumberFilled.value
    );
    setFirstNameFilled({
      value: firstNameFilled.value,
    });

    setLastNameFilled({
      value: lastNameFilled.value,
    });

    setPhoneNumberFilled({
      value: phoneNumberFilled.value,
      error: phoneNumberValidationError,
    });

    if (
      firstNameValidationError == null &&
      lastNameValidationError == null &&
      phoneNumberValidationError == null
    ) {
      window?.mixpanel?.track("Get OTP_clicked", { screen: "Home" });
      window?.trackGtagClickEvent?.("Get OTP_clicked", {
        screen: "Home",
      });
      window?.trackFbClickEvent?.("Get OTP_clicked", { screen: "Home" });
      setIsBottomSheetOpen(true);
    } else {
      console.log("found errors, can't call api");
    }
  }

  function checkFieldsValidAndCallLogin() {
    var firstNameValidationError = firstNameValidator(firstNameFilled.value);
    var lastNameValidationError = lastNameValidator(lastNameFilled.value);
    var phoneNumberValidationError = indianMobileNumberValidator(
      phoneNumberFilled.value
    );
    setFirstNameFilled({
      value: firstNameFilled.value,
    });

    setLastNameFilled({
      value: lastNameFilled.value,
    });

    setPhoneNumberFilled({
      value: phoneNumberFilled.value,
      error: phoneNumberValidationError,
    });

    if (
      firstNameValidationError == null &&
      lastNameValidationError == null &&
      phoneNumberValidationError == null
    ) {
      setIsButtonSubmissionLoading(true);
      var requestPayload = {
        phoneNumber: `+91${phoneNumberFilled.value.trim()}`,
        name: `${firstNameFilled.value.trim()} ${lastNameFilled.value.trim()}`,
        refTag: "WEB_SIGNUP",
        flow: "SIGNUP",
        enableWhatsAppNotification: whatsappCheckBoxTicked,
        firstName: firstNameFilled.value.trim(),
        lastName: lastNameFilled.value.trim(),
      };
      sendOTP(
        requestPayload,
        (otpRequestId) => {
          setIsButtonSubmissionLoading(false);
          onOtpRequestGenerated(otpRequestId, requestPayload);
        },
        (error) => {
          setIsButtonSubmissionLoading(false);
          toast.error(error.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
      window?.mixpanel?.track("confirm_phone_number_clicked", {
        screen: "Home",
      });
      window?.trackGtagClickEvent?.("confirm_phone_number_clicked", {
        screen: "Home",
      });
      window?.trackFbClickEvent?.("confirm_phone_number_clicked", {
        screen: "Home",
      });
    } else {
      console.log("found errors, can't call api");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BottomSheet open={isBottomSheetOpen} style={{ zIndex: "10" }}>
        <PhoneAadharAlertBottomSheet
          onClose={handleCloseBottomSheet}
          onClick={() => {
            if (!isButtonSubmissionLoading) {
              checkFieldsValidAndCallLogin();
            }
          }}
          isButtonSubmissionLoading={isButtonSubmissionLoading}
        />
      </BottomSheet>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ScapiaTextInput
          tabIndex={0}
          hint="First Name"
          validate={(value) => {
            firstNameValidator(value);
          }}
          maxLength={40}
          preSetError={firstNameFilled.error}
          onTextUpdate={(updatedText, error) => {
            setFirstNameFilled({ value: updatedText, error: error });
            var fullName = `${updatedText}${lastNameFilled.value}`;
            if (fullName.length > 50) {
              setFirstNameFilled({
                value: updatedText,
                error: "Full name should be less than 50 chars",
              });
            }
          }}
        />

        <div
          style={{
            width: "12px",
          }}
        />

        <ScapiaTextInput
          tabIndex={1}
          hint="Last Name"
          validate={(value) => {
            lastNameValidator(value);
          }}
          maxLength={40}
          preSetError={lastNameFilled.error}
          onTextUpdate={(updatedText, error) => {
            setLastNameFilled({ value: updatedText, error: error });
            var fullName = `${firstNameFilled.value}${updatedText}`;
            if (fullName.length > 50) {
              setLastNameFilled({
                value: updatedText,
                error: "Full name should be less than 50 chars",
              });
            }
          }}
        />
      </span>
      <span
        style={{
          marginTop: "20px",
          display: "flex",
          width: "100%",
        }}
      >
        <ScapiaTextInput
          hint="Phone number"
          validate={indianMobileNumberValidator}
          maxLength={10}
          preSetError={phoneNumberFilled.error}
          onTextUpdate={(updatedText, error) => {
            setPhoneNumberFilled({ value: updatedText, error: error });
          }}
        />
      </span>
      <span
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <ScapiaCheckBox
          check={whatsappCheckBoxTicked}
          onCheckChanged={(value) => {
            setWhatsappCheckBoxTicked(value);
          }}
        />
        <span
          className="captions01"
          style={{ color: "white", flex: 1, marginLeft: "8px" }}
        >
          I agree to receive critical communication from Scapia through Whatsapp
          (no spam!)
        </span>
      </span>

      <span
        style={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <ScapiaButton
          text="Get OTP"
          isLoading={isButtonSubmissionLoading}
          onClick={() => {
            checkFieldsAndValidate();
          }}
        />
      </span>
      <span
        style={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <IconTextWithGradient />
      </span>
    </div>
  );
}

export default LoginComponent;
