"use client";
import React, { useEffect, useRef } from "react";

const divStyle = {
  height: "100%",
  width: "100%",
  userSelect: "none",
  touchAction: "none",
};

function FlutterViewComponent({
  assetBase = "",
  src = "main.dart.js",
  onAccessTokenChange,
  accessToken,
  onFlutterAppLoadedCallback,
  onSessionExpiry,
}) {
  const flutterState = useRef(null);
  const ref = useRef(null);

  const onFlutterAppLoaded = (state) => {
    flutterState.current = state;
    state.onAccessTokenChange(onAccessTokenChange);
    state.setAccessToken(accessToken);
    onFlutterAppLoadedCallback();
  };

  useEffect(() => {
    const target = ref.current;
    let isRendered = true;

    const initFlutterApp = async () => {
      if (!isRendered) return;
      try {
        const engineInitializer = await new Promise((resolve) => {
          window._flutter.loader.loadEntrypoint({
            entrypointUrl: src,
            onEntrypointLoaded: resolve,
          });
        });

        if (!isRendered) return;

        const appRunner = await engineInitializer?.initializeEngine({
          hostElement: target,
          assetBase: assetBase,
        });

        if (!isRendered) return;

        await appRunner?.runApp();
      } catch (e) {
        console.error("Error initializing Flutter app", e);
      }
    };

    initFlutterApp();

    const eventListener = (event) => {
      let state = event.detail;
      onFlutterAppLoaded(state);
    };

    target?.addEventListener("flutter-initialized", eventListener, {
      once: true,
    });

    target?.addEventListener("session-token-expiry", onSessionExpiry);

    return () => {
      isRendered = false;
      target?.removeEventListener("flutter-initialized", eventListener);
      target?.removeEventListener("session-token-expiry", onSessionExpiry);
    };
  }, []);

  useEffect(() => {
    flutterState.current?.setAccessToken(accessToken);
  }, [accessToken]);

  return (
    <div ref={ref} style={divStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        Flutter Loading...
      </div>
    </div>
  );
}

FlutterViewComponent.displayName = "FlutterViewComponent";

export default FlutterViewComponent;
