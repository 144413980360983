import axios from "axios";

const baseServiceInstance = axios.create({
  baseURL: "https://api.scapia.in", // your API base URL
  timeout: 10000, // specify your timeout duration
});

// Interceptors to handle setting headers
baseServiceInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // assuming you store the access token in localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptors to handle responses and errors
baseServiceInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle token expiry scenario
    if (error.response && error.response.status === 401) {
      // 401 is a common status code for unauthorized
      // Clear any tokens or session data if necessary
      localStorage.removeItem("accessToken");

      // Redirect user to the login page
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default baseServiceInstance;
