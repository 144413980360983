export default function ScapiaFederalTopBannerScapiaText() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src="/images/federal_scapia_logo.png"
        alt="Scapia Logo"
        style={{ height: "30px" }}
      />
    </div>
  );
}
