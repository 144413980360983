import baseServiceInstance from "../network/baseServiceInstance";

export function sendOTP(requestPayload, onSuccess, onFailure) {
  baseServiceInstance
    .post("/api/sendOTP", requestPayload)
    .then((response) => {
      onSuccess(response.data.otpRequestId, requestPayload);
})
    .catch((error) => {
      onFailure(error.response.data);
    });
}

export function verifyOTP(requestPayload, onSuccess, onFailure) {
  baseServiceInstance
    .post("/api/verifyOTP", requestPayload)
    .then((response) => {
      onSuccess(response.data, requestPayload);
    })
    .catch((error) => {
        onFailure(error.response.data);
    });
}

export function setAquisitionSource(requestPayload, onSuccess, onFailure) {
  baseServiceInstance
    .post("/api/user/acquisition/source", requestPayload)
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      onFailure(error);
    });
}
