"use client";
import styles from "./components.module.css";
import { useEffect, useState } from "react";

export default function ScapiaTextInput({
  hint,
  validate,
  maxLength,
  preSetError = null,
  onTextUpdate,
  theme = "dark",
}) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(preSetError);
  const [errorShownOnce, setErrorShownOnce] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(theme);

  useEffect(() => {
    setCurrentTheme(theme);
  }, [theme]);

  useEffect(() => {
    setError(preSetError);
  }, [preSetError]);

  const handleChange = (e) => {
    setValue(e.target.value);
    var currentValue = e.target.value;
    onTextUpdate(currentValue, null);

    if (validate && errorShownOnce) {
      const errorMessage = validate(currentValue);
      setError(errorMessage);
      if (errorMessage != null) {
        setErrorShownOnce(true);
      }
      onTextUpdate(value, errorMessage);
    }
  };

  const handleBlur = () => {
    if (validate && !error) {
      const errorMessage = validate(value);
      setError(errorMessage);
      if (errorMessage != null) {
        setErrorShownOnce(true);
      }
      onTextUpdate(value, errorMessage);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <input
        className={[
          error ? styles.errorInput : "",
          theme == "light" ? styles.inputText : styles.inputTextDark,
          "body01",
        ].join(" ")}
        placeholder={hint}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
      {error && (
        <div
          style={{ marginTop: "10px" }}
          className={`helperText01 ${styles.errorText}`}
        >
          {error}
        </div>
      )}
    </div>
  );
}
