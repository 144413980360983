import ReactPlayer from "react-player";
import FlutterViewComponent from "./components/FlutterViewComponent";
import LoginComponent from "./components/LoginComponent";
import OtpComponent from "./components/OtpComponent";

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ScapiaFederalTopBannerScapiaText from "./components/ScapiaFederalTopBannerScapiaText";
import {ToastContainer} from "react-toastify";

const PageState = {
  Loading: "LOADING",
  LoginView: "LOGIN_VIEW",
  OtpView: "OTP_VIEW",
  LoggedIn: "LOGGED_IN",
};

const FlutterLoadingState = {
  FlutterLoading: "FLUTTER_LOADING",
  FlutterLoaded: "FLUTTER_LOADED",
};

export default function HomePage({ url = "" }) {
  const [pageState, setPageState] = useState(PageState.Loading);
  const [otpRequestId, setOtpRequestId] = useState(null);
  const [sendOTPRequestPayload, setSendOTPRequestPayload] = useState(null);
  const [flutterLoadingState, setFlutterLoadingState] = useState(
    FlutterLoadingState.FlutterLoading
  );
  const [userData, setUserData] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    videoElement.addEventListener("loadeddata", () => {
      setVideoLoaded(true);
    });

    return () => {
      videoElement.removeEventListener("loadeddata", () => {});
    };
  }, []);

  useEffect(() => {
    setPageState(PageState.Loading);
    const userDataValue = JSON.parse(localStorage.getItem("userData"));

    if (window.mixpanel.init) {
      window.mixpanel.init("9b52e3b2445326d50679afe47ea5b00d", {
        loaded: (mixpanel) => {
          mixpanel?.track("Home_landed");
          window?.trackGtagEvent("Home_landed");
          window?.trackFbEvent("Home_landed");
        },
      });
    }

    if (userDataValue !== null) {
      setUserData(userDataValue);
      setPageState(PageState.LoggedIn);
    } else {
      setPageState(PageState.LoginView);
    }
  }, []);

  const renderChildComponent = () => {
    switch (pageState) {
      case PageState.Loading:
        return <div style={{ color: "white" }}>Page Loading...</div>;
      case PageState.LoginView:
        return (
          <LoginComponent
            onOtpRequestGenerated={(otpRequestId, requestPayload) => {
              setSendOTPRequestPayload(requestPayload);
              setOtpRequestId(otpRequestId);
              setPageState(PageState.OtpView);
            }}
          />
        );
      case PageState.LoggedIn:
        return <div></div>;
      case PageState.OtpView:
        return (
          <OtpComponent
            initialOtpRequestId={otpRequestId}
            sendOTPRequestPayload={sendOTPRequestPayload}
            onOtpRequestVerified={(userDataValue) => {
              setUserData(userDataValue);
              if (userDataValue != null) {
                setPageState(PageState.LoggedIn);
              }
            }}
          />
        );
      default:
        console.log(
          "only supported page states are Loading, LoginView, OtpView, LoggedIn"
        );
        return <div></div>;
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#000000",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
        <ToastContainer />
      <div
        style={{
          display:
            (flutterLoadingState == FlutterLoadingState.FlutterLoading &&
              pageState == PageState.LoggedIn) ||
            pageState != PageState.LoggedIn
              ? "block"
              : "none",
        }}
      >
        <div
          style={{
            marginTop: "20px",
            position: "absolute",
            left: "0",
            right: "0",
          }}
        >
          <ScapiaFederalTopBannerScapiaText />
        </div>

        <div
          className="video-container"
          style={{
            marginTop: "20px",
          }}
        >
          <ReactPlayer
            url="https://res.cloudinary.com/scapiacards/video/upload/q_auto:good/v1703149749/msite/Website_cvp_compressed_mjbt0u.mp4"
            playing
            loop
            muted
            id="background-video"
            width="100%"
            height={isMobile ? "100%" : "50vh"}
            webkit-playsinline
            playsinline
            disableRemotePlayback
          />
        </div>

        <div
          style={{
            boxSizing: "border-box",
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            backgroundColor: "#000",
            border: "1px solid #262B30",
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "30px",
            padding: "30px",
            zIndex: "0",
          }}
        >
          {renderChildComponent()}
        </div>
      </div>

      <div
        style={{
          height: "100%",
          overflow: "visible",
          flexDirection: "column",
          flexGrow: 1,
          display:
            flutterLoadingState == FlutterLoadingState.FlutterLoaded &&
            pageState == PageState.LoggedIn
              ? "flex"
              : "none",
        }}
      >
        <FlutterViewComponent
          assetBase={"/flutter/"}
          src={"/flutter/main.dart.js"}
          accessToken={userData}
          onAccessTokenChange={(updateUserData) => {
            setUserData(updateUserData);
          }}
          onFlutterAppLoadedCallback={() => {
            setFlutterLoadingState(FlutterLoadingState.FlutterLoaded);
          }}
          onSessionExpiry={() => {
            setPageState(PageState.LoginView);
          }}
        />
      </div>
    </div>
  );
}
