"use client";
import styles from "./components.module.css";
import { useState } from "react";
import { Check } from "react-feather";

function ScapiaCheckBox({ check, onCheckChanged }) {
  const [value, setValue] = useState(check);

  const handleClick = () => {
    onCheckChanged(!value);
    setValue(!value);
  };

  return (
    <button
      className={value ? styles.checked : styles.unChecked}
      onClick={handleClick}
    >
      {value ? (
        <Check
          className={styles.checkChild}
          color="white"
          size="16px"
          style={{ padding: 0 }}
        />
      ) : (
        <span></span>
      )}
    </button>
  );
}

export default ScapiaCheckBox;
