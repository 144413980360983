"use client";
import moment from "moment";

export function firstNameValidator(input) {
  if (!input || input.trim() === "") {
    return "First name is required";
  }

  if (!/^[a-zA-Z\s]+$/.test(input)) {
    return "First name is invalid";
  }

  return null;
}

export function lastNameValidator(input) {
  if (!input || input.trim() === "") {
    return "Last name is required";
  }

  if (!/^[a-zA-Z\s]+$/.test(input)) {
    return "Last name is invalid";
  }

  return null;
}

export function fullNameMaxLengthValidator(input) {
  if (!input || input.trim() === "") {
    return "Full name is required";
  }

  if (input.length > 50) {
    return "Full name cannot be more than 50 characters";
  }

  return null;
}

export function indianMobileNumberValidator(input) {
  if (!input || input.trim() === "") {
    return "Mobile number is required";
  }

  if (input.startsWith("0")) {
    return "Mobile number cannot start with 0";
  }

  if (/[^0-9]/.test(input)) {
    return "Mobile number can only contain numbers";
  }

  if (input.length !== 10) {
    return "Mobile number must be 10 digits";
  }

  return null;
}

export function cibilOTPValidator(input) {
  if (!input || input.length !== 6) {
    return "Looks like you missed something! Check the OTP again";
  }

  return null;
}

export function emailAddressValidator(input) {
  const allowedTLDs = [
    "com",
    "in",
    "co.in",
    "org",
    "net",
    "co",
    "gov",
    "cards",
    "app",
    "ac.in",
    "edu.in",
    "gov.in",
  ];

  if (!input || input.trim() === "") {
    return "Email address is required";
  }

  if (!input.includes("@")) {
    return "Email address must contain @";
  }

  if (/[^a-zA-Z0-9@._]/.test(input)) {
    return "Email address can only contain alphabets, numbers, @, . and _";
  }

  // Here, we're skipping a duplicated email validation. Instead, we should rely on a proper regex or library.
  const domain = input.split("@")[1];
  const tld = domain.split(".").slice(-2).join(".");
  if (!allowedTLDs.includes(tld)) {
    return "Email address seems to be invalid";
  }

  return null;
}

function panNumberValidator(input) {
  if (!input || input.trim() === "") {
    return "PAN number is required";
  }

  if (input.length !== 10) {
    return "PAN number must be 10 characters";
  }

  if (/[^a-zA-Z0-9]/.test(input)) {
    return "PAN number can only contain alphabets and numbers";
  }

  if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(input)) {
    return "PAN number seems to be invalid";
  }

  return null;
}

function dateOfBirthValidator(input) {
  if (!input || input.trim() === "") {
    return "Date of birth is required";
  }

  if (input.length !== 10) {
    return "Date of birth seems to be invalid";
  }

  if (/[^0-9-]/.test(input)) {
    return "Date of birth can only contain numbers";
  }

  const isValidFormat = moment(input, "DD-MM-YYYY", true).isValid();
  if (!isValidFormat) {
    return "Date of birth seems to be invalid";
  }

  const date = moment(input, "DD-MM-YYYY");
  const today = moment();
  const minDate = moment().subtract(60, "years");
  const maxDate = moment().subtract(23, "years");

  if (date.isBefore(minDate) || date.isAfter(maxDate)) {
    return "The age must be above 23 and below 60";
  }

  return null;
}
