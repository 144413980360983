"use client";
import styles from "./components.module.css";

function ScapiaButton({ text, isLoading, onClick }) {
  return (
    <button
      className={styles.scapiaButton}
      onClick={onClick}
      style={{
        border: "0",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        cursor: "pointer",
      }}
    >
      {isLoading ? (
        <span style={{ height: "22px" }}>
          <div className="dot-flashing"></div>
        </span>
      ) : (
        <span className="body02">{text}</span>
      )}
    </button>
  );
}

export default ScapiaButton;
