"use client";
import { useEffect, useState } from "react";
import styles from "./components.module.css";
import ScapiaTextInput from "./ScapiaTextInput";
import ScapiaCheckBox from "./ScapiaCheckBox";
import ScapiaFederalTopBanner from "./ScapiaFederalTopBanner";
import OtpInput from "react-otp-input";
import ScapiaButton from "./ScapiaButton";
import IconTextWithGradient from "./IconTextWithGradient";

import {
  verifyOTP,
  sendOTP,
  setAquisitionSource,
} from "../network/loginService";
import {toast} from "react-toastify";

const OTPInputState = {
  Neutral: "NEUTRAL",
  Success: "SUCCESS",
  Error: "ERROR",
};

function getAquisitionSource() {
  const searchParams = new URLSearchParams(window.location.search);
  console.log(searchParams);
  var aquisitionSourceData = {};
  for (let [k, v] of searchParams.entries()) {
    if (k.toLowerCase() === "utm_source" || k.toLowerCase() === "pid") {
      aquisitionSourceData["utm_source"] = v;
    } else if (k.toLowerCase() === "utm_campaign" || k.toLowerCase() === "c") {
      aquisitionSourceData["utm_campaign"] = v;
    } else if (k.toLowerCase() === "utm_medium") {
      aquisitionSourceData["utm_medium"] = v;
    }
  }
  var aquisitionSource = {
    trackerType: "UTM",
    data: aquisitionSourceData,
  };
  return aquisitionSource;
}

function OtpComponent({
  initialOtpRequestId,
  sendOTPRequestPayload,
  onOtpRequestVerified,
}) {
  const [countDownTime, setCountDownTime] = useState(120);
  const [otpRequestId, setOtpRequestId] = useState(initialOtpRequestId);
  const [otp, setOtp] = useState("");
  const [userData, setUserData] = useState({});
  const [otpInputState, setOtpInputState] = useState(OTPInputState.Neutral);
  const [isButtonSubmissionLoading, setIsButtonSubmissionLoading] =
    useState(false);

  //following function converts integer seconds to 00:00 notation
  function convertSecondsToMinutesAndSeconds(seconds) {
    var minutes = Math.floor(seconds / 60);
    var seconds = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  }

  useEffect(() => {
    // window?.initiateFcCall(
    //   sendOTPRequestPayload.firstName,
    //   sendOTPRequestPayload.lastName,
    //   sendOTPRequestPayload.phoneNumber,
    //   null
    // );
  }, []);

  useEffect(() => {
    setOtpRequestId(otpRequestId);
  }, [otpRequestId]);

  useEffect(() => {
    if (otp.length == 6) {
      setOtpInputState(OTPInputState.Success);
    } else {
      setOtpInputState(OTPInputState.Neutral);
    }
  }, [otp]);

  useEffect(() => {
    // Set up the timeout
    if (countDownTime === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      setCountDownTime(countDownTime - 1);
    }, 1000); // 1000 milliseconds = 1 second

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeout);
  }, [countDownTime]);

  var renderChildComponentForResend = () => {
    if (countDownTime > 0) {
      return (
        <span
          style={{ marginLeft: "4px", color: "#388CEB", cursor: "pointer" }}
        >
          Resend in {convertSecondsToMinutesAndSeconds(countDownTime)}
        </span>
      );
    } else {
      return (
        <span
          style={{ marginLeft: "4px", color: "#388CEB", cursor: "pointer" }}
          onClick={() => {
            resendOtp();
          }}
        >
          Resend
        </span>
      );
    }
  };

  function resendOtp() {
    sendOTP(
      sendOTPRequestPayload,
      (responseOtpRequestId) => {
        setOtpRequestId(responseOtpRequestId);
        setCountDownTime(120);
      },
      (error) => {
        //todo: handle error
      }
    );
  }

  function submitOtp() {
    var verifyOTPRequestPayload = {
      otpRequestId: otpRequestId,
      otp: otp,
      enableWhatsAppNotification:
        sendOTPRequestPayload.enableWhatsAppNotification,
    };
    setIsButtonSubmissionLoading(true);
    verifyOTP(
      verifyOTPRequestPayload,
      (response) => {
        var createdUserData = {
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          firstName: sendOTPRequestPayload.firstName,
          lastName: sendOTPRequestPayload.lastName,
          userId: response.userId,
          phoneNumber: sendOTPRequestPayload.phoneNumber,
        };
        setUserData(createdUserData);
        localStorage.setItem("userData", JSON.stringify(createdUserData));
        localStorage.setItem("accessToken", response.accessToken);

        //mixpanel
        window?.mixpanel?.identify(response.userId);
        window?.mixpanel?.people.set({ scapia_user_id: response.userId });

        //set post login values
        window?.setMoeOnboardingPlatform();
        window?.setMoeUserId(response.userId);
        window?.setMoeUserFirstName(sendOTPRequestPayload.firstName);
        window?.setMoeUserLastName(sendOTPRequestPayload.lastName);
        window?.setMoeUserPhone(sendOTPRequestPayload.phoneNumber);
        window?.setNewRelicUserId(response.userId);
        window?.AF("pba", "setCustomerUserId", response.userId);

        //call aquisition source api
        var aquisitionSource = getAquisitionSource();
        setAquisitionSource(
          aquisitionSource,
          (response) => {
            console.log("aquisition source set");
          },
          (error) => {
            console.log("aquisition source set failed");
          }
        );

        window?.mixpanel?.track(
          "acquisition_source_network_event",
          aquisitionSource.data
        );
        window?.trackGtagEvent("acquisition_source_network_event");
        window?.trackFbEvent("acquisition_source_network_event");

        window?.fcWidget?.setExternalId(response.userId);

        onOtpRequestVerified(createdUserData);
        setIsButtonSubmissionLoading(false);
        setOtpInputState(OTPInputState.Success);
      },
      (error) => {
        setIsButtonSubmissionLoading(false);
        setOtpInputState(OTPInputState.Error);
          toast.error(error.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
      }
    );
    window?.mixpanel?.track("Verify OTP_clicked", { screen: "Home" });
    window?.trackGtagClickEvent?.("Verify OTP_clicked", {
      screen: "Home",
    });
    window?.trackFbClickEvent?.("Verify OTP_clicked", { screen: "Home" });
  }

  return (
    <div
      className={styles.main}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="body01"
        style={{
          marginTop: "8px",
          color: "#fff",
          display: "flex",
          flexDirection: "row",
        }}
      >
        Enter the OTP sent to {sendOTPRequestPayload.phoneNumber}
      </div>

      <span
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <OtpInput
          inputStyle={`body02 ${styles.otpInput}`}
          inputType="number"
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ width: "8px" }}></span>}
          renderInput={(props) => <input {...props} />}
        />
      </span>

      <span
        style={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          color: "white",
        }}
        className="body01"
      >
        Did not get OTP?{renderChildComponentForResend()}
      </span>
      <span
        style={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <ScapiaButton
          text="Continue"
          isLoading={isButtonSubmissionLoading}
          onClick={() => {
            if (!isButtonSubmissionLoading) {
              submitOtp();
            }
          }}
        />
      </span>
      <span
        style={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <IconTextWithGradient />
      </span>
    </div>
  );
}

export default OtpComponent;
