import React from "react";
import ScapiaButton from "./ScapiaButton";
import { X } from "react-feather";

const PhoneAadharAlertBottomSheet = ({
  onClose,
  onClick,
  isButtonSubmissionLoading,
  onClickNotLinked,
}) => {
  return (
    <div style={{ zIndex: "10" }}>
      <div
        style={{
          margin: "20px 40px 0 20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <X
          onClick={onClose}
          style={{
            cursor: "pointer",
            fontSize: "24px",
            height: "24px",
            width: "24px",
            color: "#000000",
          }}
        ></X>
        <p
          style={{
            fontSize: "16px",
            wordWrap: "break-word",
            color: "#262B30",
            fontFamily: "LexendDeca",
            flex: "auto",
            textAlign: "center",
            padding: "0 20px 0 20px",
          }}
        >
          Is your mobile number linked to Aadhaar?
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 20px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 2,
            width: "100vw",
            background: "#F1F6FB",
            margin: "20px 0",
          }}
        ></div>
        <img src="images/aadhar_mobile_link_vector.webp" alt="" height="147" />
        <div style={{ height: 35, width: 100 }}></div>
        <p
          style={{
            textAlign: "center",
            fontSize: 14,
            fontWeight: 400,
            color: "#262B30",
            margin: "0",
            fontFamily: "LexendDeca",
          }}
        >
          This phone number must be linked with your Aadhaar and must be in use
          on this device.
        </p>
        <p
          style={{
            textAlign: "center",
            fontSize: 14,
            fontWeight: 600,
            color: "#262B30",
            margin: "0",
            padding: "0 20px 0 20px",
            fontFamily: "LexendDeca",
          }}
        >
          This is mandatory for your Credit card approval
        </p>
        <div style={{ height: 31, width: 100 }}></div>
        <ScapiaButton
          text="Yes, it’s linked to my Aadhaar"
          isLoading={isButtonSubmissionLoading}
          onClick={onClick}
        />
      </div>
      <div style={{ height: 32, width: 100 }}></div>
    </div>
  );
};

export default PhoneAadharAlertBottomSheet;
